import React from "react";
import styled from "@emotion/styled";
import { Image, ImageGallery } from "./Images";
import { Title, Subtitle, Paragraph } from "./Text";

const AboutUsContainer = styled.div`
  text-align: center;
`;

function AboutUs() {
  const fordhamGalleryImages = [
    {
      src: "/images/m&a-early-days.jpg",
      alt: "Selfie of Anthony and Michaela in the early days",
    },
    {
      src: "/images/m&a-early-days-02.jpg",
      alt: "Picture of Anthony and Michaela at Fordham",
    },
    {
      src: "/images/m&a-graduation.jpg",
      alt: "Michaela and Anthony showing at graduation",
    },
  ];

  const journeyGalleryImages = [
    {
      src: "/images/m&a-paris.jpg",
      alt: "Selfie of Anthony and Michaela by the Eiffel Tower",
    },
    {
      src: "/images/michaela&ziti.jpg",
      alt: "Picture of Michaela and Ziti",
    },
    {
      src: "/images/m&a_at_dca.jpg",
      alt: "Picture of Anthony and Michaela at the DCA"
    }
  ];

  const portraitImageScaling = "32%";

  return (
    <AboutUsContainer>
     <Title>About Us</Title>
      <ImageGallery
        images={fordhamGalleryImages}
        imageHeight={portraitImageScaling}
        imageWidth={portraitImageScaling}
      />
      <Paragraph>
        Our journey together began during orientation at Fordham University in
        2014, where we first met amongst our fellow commuter students. We
        quickly became inseparable, and by the end of the year we were
        thoroughly obsessed with each other. Through challenging course work,
        and a worrying number of grilled cheese sandwiches, we began to build
        the foundations of our relationship. By graduation in 2018, we had
        forged many of our closest friendships, and firmly solidified our
        commitment to each other.
      </Paragraph>
      <ImageGallery
        images={journeyGalleryImages}
        imageHeight={portraitImageScaling}
        imageWidth={portraitImageScaling}
      />
      <Paragraph>
        In the years since, the love we found has only grown stronger. Through
        our truly daring travels, three distinctly Italian apartments, and one
        too many pandemics, we've been on many adventures together - and with
        our adopted over-sized, orange rat, we plan on having many more!
      </Paragraph>
      <Paragraph>
        With the company of our closest friends and family, we hope that our
        wedding day will be one of highest peaks of our journey thus far.
      </Paragraph>
      <Image
        src="/images/Motto and Michaela-45.jpg"
        alt="Picture of Anthony and Michaela showing off the ring"
      />
    </AboutUsContainer>
  );
}

export default AboutUs;
