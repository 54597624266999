import React, { useState } from "react";
import styled from "@emotion/styled";
import { Title, Paragraph } from "./Text";

const RSVPContainer = styled.div`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
`;

const Select = styled.select`
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

function RSVP() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    attending: "yes",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to a server
    alert("Thank you for your RSVP!");
  };

  return (
    <RSVPContainer>
      <Title>RSVP</Title>
      <p>Coming soon!</p>
      {/* <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <Select
          name="attending"
          value={formData.attending}
          onChange={handleChange}
          required
        >
          <option value="yes">Yes, I'll be there</option>
          <option value="no">Sorry, I can't make it</option>
        </Select>
        <Button type="submit">Submit</Button>
      </Form> */}
    </RSVPContainer>
  );
}

export default RSVP;
