import styled from "@emotion/styled";

export const Title = styled.h1`
  color: var(--color-primary);
  font-family: EyesomeScript, cursive;
  font-size: clamp(2rem, 16vw, 4.8rem);
  white-space: nowrap;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.h2`
  color: var(--color-text);
  font-family: EyesomeScript, cursive;
  font-size: clamp(1rem, 6vw, 2rem);
  white-space: nowrap;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: justify;
  color: var(--color-text);
`;
