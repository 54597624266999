import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Global, css } from "@emotion/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Global
      styles={css`
        @font-face {
          font-family: "EyesomeScript";
          src: url("/fonts/eyesome-duo-script.otf") format("opentype");
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "Montserrat";
          src: url("/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
          font-weight: normal;
          font-style: normal;
        }

        body {
          margin: 0;
          font-family:
            "EyesomeScript",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: var(--color-text);
          background-color: var(--color-background);
        }
      `}
    />
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
