import React from "react";
import styled from "@emotion/styled";
import { Title } from "./Text";

const GalleryContainer = styled.div`
  text-align: center;
`;

function Registry() {
  return (
    <GalleryContainer>
      <Title>Registry</Title>
      <p>Coming soonish!</p>
    </GalleryContainer>
  );
}

export default Registry;
