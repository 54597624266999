import React from "react";
import styled from "@emotion/styled";

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: flex-start;
  gap: 1rem;
  margin: 2rem 0;
  padding-bottom: 1rem;

  & img:first-child {
    margin-left: auto;
  }
  & img:last-child {
    margin-right: auto;
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || "45%"}; /* Set a fixed width */
  height: ${(props) => props.height || "45%"}; /* Set a fixed height */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  flex-shrink: 0; /* Prevent images from shrinking */

  @media (max-width: 720px) {
    height: 90%; /* Smaller width for mobile devices */
    width: 90%; /* Smaller height for mobile devices */
  }
`;

export const ImageGallery = ({
  images,
  imageWidth,
  imageHeight,
}) => {
  return (
    <GalleryContainer>
      {images.map((image, index) => (
        <StyledImage
          src={image.src}
          alt={image.alt || `Image ${index + 1}`}
          width={imageWidth}
          height={imageHeight}
        />
      ))}
    </GalleryContainer>
  );
};
