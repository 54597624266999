export const lightTheme = {
  colors: {
    // Lilac shades
    primary: "#D0C3F1", // Main lilac color

    // Olive green shades
    accent: "#3D5919", // Main olive green
    accentLight: "#A4B792", // Lighter olive green

    // Text and background colors
    text: "#333333", // Dark gray for main text
    textNav: "#333333", // Color for nav links
    link: "#3D5919",
    background: "#FFFBF5",
    backgroundBlurred: "rgba(255, 255, 255, 0.3)",

    // Margin colors
    marginBorder: "#E0E0E0",
    marginBorderLilac: "#EAEBFF",
  },
};

export const darkTheme = {
  colors: {
    // Lilac shades
    primary: "#D0C3F1", // Main lilac color

    // Olive green shades
    accent: "#3D5919", // Main olive green
    accentLight: "#A4B792", // Lighter olive green

    // Text and background colors
    text: "#FFFFFF", // Dark gray for main text
    textNav: "#333333", // Color for nav links
    background: "#191918",
    backgroundBlurred: "rgba(25, 25, 24, 0.3)",
    link: "#A4B792",

    // Margin colors
    marginBorder: "#E0E0E0",
    marginBorderLilac: "#EAEBFF",
  },
};

export const createCssVariables = (theme) => `
    :root {
        --color-primary: ${theme.colors.primary};
        --color-primary-light: ${theme.colors.primaryLight};

        --color-accent: ${theme.colors.accent};
        --color-accent-light: ${theme.colors.accentLight};

        --color-text: ${theme.colors.text};
        --color-text-light: ${theme.colors.textLight};
        --color-text-nav: ${theme.colors.textNav};
        --color-background: ${theme.colors.background};
        --color-background-blurred: ${theme.colors.backgroundBlurred};
        --color-link: ${theme.colors.link};

        --color-margin-border: ${theme.colors.marginBorder};
        --color-margin-border-lilac: ${theme.colors.marginBorderLilac};
    }
  `;
